// src/Assets/PagesData.js
export const sidebarData = {
  exchange: {
    profileImg: require('./profile.png'), // Check this path
    profileName: 'Amaga Exchange',
    items: [
      { name: 'Exchange stock', path: '/exchange/exchangestock' },
      { name: 'Exchange History', path: '/exchange/exchangehistory' },
      { name: 'Receive History', path: '/exchange/receivehistory' }
    ],
    buttons: [
      { text: 'Log out', action: 'logout' }
    ]
  },
  rent: {
    profileImg: require('./profile.png'), // Check this path
    profileName: 'Amaga Rent',
    items: [
      { name: 'Rent Cylinder', path: '/rent/rentcylinder' },
      { name: 'Rent History', path: '/rent/renthistory' },
      { name: 'Receive History', path: '/rent/receivehistory' }
    ],
    buttons: [
     
      { text: 'Log out', action: 'logout' }
    ]
  },
  sales: {
    profileImg: require('./profile.png'), // Check this path
    profileName: 'Amaga Sales',
    items: [
      { name: 'Sales Cylinder', path: '/sales/salesdashboard' },
      // { name: 'Rent History', path: '/rent/renthistory' },
      // { name: 'Receive History', path: '/rent/receivehistory' }
    ],
    buttons: [
     
      { text: 'Log out', action: 'logout' }
    ]
  },
  // Add more page data as needed
};
