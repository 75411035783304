import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import axios from 'axios';

const ExchangeMainContent = ({ buttonType, onItemClick, searchQuery = '', setShowExchangeContainer }) => {
  // const [items, setItems] = useState([]);
  // const [cylinders, setCylinders] = useState([]);
  // const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 15;
  const containerRef = useRef(null);
  // const [showCustomerCard, setShowCustomerCard] = useState(false);
  const [customers, setCustomers] = useState([]);

  // Fetch customers with serviceType 'E'
  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://aga.hancecybersecurity.com/api/Auth/Users/api/Product/ByCustomers?serviceType=E');
      if (Array.isArray(response.data)) {
        const updatedCustomers = response.data.map((customer) => ({
          ...customer,
          totalCylinders: customer.totalCylinders || 0,
        }));
        setCustomers(updatedCustomers);
        setTotalPages(Math.ceil(updatedCustomers.length / itemsPerPage));
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      // console.log('Error fetching customers:');
      // console.log(`Failed to fetch customers`);
      // console.log('Error', `Failed to fetch customers`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  const filteredItems = customers.filter(item =>
    item.customerName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalItems = filteredItems.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

  const handleItemClick = (clickedItem) => {
    // console.log(clickedItem)
    // setSelectedItem(clickedItem);
    setShowExchangeContainer(true);

    if (onItemClick) {
      onItemClick(clickedItem); // Pass the selected item including cylinders
    }

    // setShowCustomerCard(true);
  };

  // const handleCloseCustomerCard = () => {
  //   setShowCustomerCard(false);
  // };

  // const handleAddToExchange = (cylinders) => {
  //   console.log('Cylinders to exchange:', cylinders);
  // };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto pl-8 pr-8" ref={containerRef}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ul className="space-y-4">
            {paginatedItems.map(item => (
              <li
                key={item.customerId}
                className={`relative flex items-center p-2 pl-8 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100`}
                onClick={() => handleItemClick(item)}
              >
                <div className="flex flex-col flex-1">
                  <span className="mb-1 text-[#003366]">{item.customerName || 'N/A'}</span>
                  <span className="text-sm text-gray-500">{item.email || 'N/A'}</span>
                  <span className="text-sm text-gray-500">{item.phone || 'N/A'}</span>
                </div>
                <div className="flex items-center ml-12 mt-12">
                  <span className="text-sm text-gray-500">{item.totalCylinders || 0} cylinders</span>
                </div>
                {buttonType !== 'addExchange' && buttonType !== 'addCustomer' && buttonType !== 'checkbox' && (
                  <div className="ml-4">
                    <FiChevronRight className="text-gray-500 w-6 h-6" />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="pb-12 pt-4 bg-white border-t border-gray-300 flex flex-col items-center">
        <div className="flex justify-center items-center space-x-4 mb-2">
          <button
            className="px-4 py-2 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-xs text-gray-600">
            Page {currentPage} of {totalPages}, Showing {startIndex + 1} to {Math.min(startIndex + itemsPerPage, totalItems)} of {totalItems} items
          </span>
          <button
            className="px-4 py-2 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* {showCustomerCard && selectedItem && (
        <CustomerCylindersCard
          customerName={selectedItem?.customerName}
          numberOfCylinders={selectedItem?.totalCylinders}
          cylinders={selectedItem ? customers.find(customer => customer.customerId === selectedItem.customerId)?.cylinders || [] : []}
          onClose={handleCloseCustomerCard}
          onAddToExchange={handleAddToExchange}
        />
      )} */}
    </div>
  );
};

export default ExchangeMainContent;
