import React, { useState } from 'react';
import { FiChevronRight, FiSquare, FiX } from 'react-icons/fi';
import CylinderDetail from './CylinderDetail';
import { useRentContext } from '../Context/RentContext';
import PhoneNumberSearch from './PhoneNumberSearch';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { submitRentData } from '../api/rent/rentApi'; // Import the API function

const RentCylinderCard = ({ items, onClose }) => {
  console.log("33",items);
  const [documentNumber, setDocumentNumber] = useState('');
  const [customerId, setCustomerId] = useState(null); // Set to null initially
  const [selectedItem, setSelectedItem] = useState(null);
  const { addRentData } = useRentContext();
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleCustomerSelect = (customer) => {
    // Ensure customer is an object with id and name
    setSelectedCustomer(customer);
    setCustomerId(customer.id); // Set the actual customer ID
    console.log('Selected Customer:', customer);
  };

  const navigate = useNavigate();

  const handleRent = async () => {
    if (!customerId || !documentNumber) {
      toast.error('Please provide all required fields.');
      return;
    }

    const rentData = {
      documentNumber,
      customerId,
      productIds: items.map(item => ({
        serialNumber: item.serialNumber, // Ensure item has a serialNumber field
        productId: item.productId // Ensure item.id is the productId
      }))
    };

    try {
      await submitRentData(rentData); // Call the API function
      addRentData(rentData); // Update context with rent data
      navigate('/rent/renthistory');
      toast.success('Rent data submitted successfully!');
    } catch (error) {
      toast.error('Failed to submit rent data.');
      console.error(error.message);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white w-[80vw] max-w-3xl p-8 rounded shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Rent Cylinders</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-900">
            <FiX className="w-6 h-6" />
          </button>
        </div>
        <PhoneNumberSearch onCustomerSelect={handleCustomerSelect} />

        <div className="space-y-4">
          <div className="mb-4">
            <input
              type="text"
              id="documentNumber"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
              placeholder="Enter Document Number"
              className="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="customerId"
              value={selectedCustomer ? selectedCustomer.name : ''}
              placeholder="Customer Name"
              className="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              disabled
            />
          </div>
          
          <label htmlFor="customerId" className="block text-lg font-medium text-gray-900">
            Cylinders
          </label>

          <div className="max-h-60 overflow-y-auto">
            {Array.isArray(items) ? (
              items.map((item) => (
                <div
                  key={item.id}
                  className={`relative flex justify-between items-center p-2 pl-8 pr-4 mt-2 border border-gray-300 rounded ${item.selected ? 'font-bold bg-white' : ''} cursor-pointer`}
                  style={{ borderRadius: '0.5rem', backgroundColor: '#FFFFFF' }}
                  onClick={() => setSelectedItem(item)}
                >
                  <div
                    className="absolute left-0 top-0 bottom-0 w-5 rounded-l"
                    style={{ backgroundColor: item.color }}
                  />
                  <div className="flex flex-col">
                    <span className="mb-1 text-[#003366]">{item.serialNumber}</span>
                    <span className="text-sm text-gray-500">{item.liters}</span>
                  </div>
                  <div className="ml-auto relative flex items-center justify-center" style={{ width: '1.5rem', height: '1.5rem' }}>
                    {item.selected ? <FiChevronRight className="text-gray-500 w-6 h-6" /> : <FiSquare className="text-gray-500 w-6 h-6" />}
                  </div>
                </div>
              ))
            ) : (
              <p>No items available</p>
            )}
          </div>

        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={handleRent}
            className="px-4 py-2 bg-[#4EA89E] text-white rounded hover:bg-blue-600"
          >
            Rent
          </button>
        </div>

        {selectedItem && (
          <CylinderDetail 
            item={selectedItem} 
            onClose={() => setSelectedItem(null)} 
          />
        )}
      </div>
    </div>
  );
};

export default RentCylinderCard;
