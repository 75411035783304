// pages/Rent.js
import React, { useState } from 'react';
import Sidebar from '../../Component/Sidebar'; 
import TopBar from '../../Component/TopBar';
import AddRentCustomerCard from '../../Component/AddRentCustomerCard';
import SalesMainContent from '../../Component/SalesMainContent';

const Sales = () => {
  const [activeItem, setActiveItem] = useState('Sales Cylinder');
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [buttonType, setButtonType] = useState('checkbox');

  const handleSetActiveItem = (item) => {
    setActiveItem(item);
    setShowForm(false);
    setButtonType('checkbox');
  };

  const handleShowForm = (type) => {
    setButtonType(type);
    setShowForm(true);
  }; 

  return (
    <div className="relative flex h-screen">
      <Sidebar
        setActiveItem={handleSetActiveItem}
        setShowForm={handleShowForm}
        setButtonType={setButtonType}
        pageType="sales"
        activeItem={activeItem}
      />
      <div className="flex flex-col flex-grow">
        <TopBar setSearchQuery={setSearchQuery} />
        <div className="flex-1 overflow-y-auto">
          <SalesMainContent
            buttonType={buttonType}
            searchQuery={searchQuery}
            pageType="sales"
          />
        </div>
        {showForm && buttonType === 'addCustomer' && (
          <AddRentCustomerCard onClose={() => setShowForm(false)} />
        )} 
      </div>
    </div>
  );
};

export default Sales;
