import React, { useState, useEffect, useRef } from 'react';
import { FiChevronRight, FiCheckSquare, FiSquare } from 'react-icons/fi';
// import {
//   LineChart, Line, BarChart, Bar, AreaChart, Area, RadarChart, Radar, PolarGrid,
//   PolarAngleAxis, PolarRadiusAxis, XAxis, YAxis, Tooltip, CartesianGrid, Legend,
//   ResponsiveContainer
// } from 'recharts';
import cylinder from '../../Assets/cylinder.png';
import AdminSidebar from '../../Component/AdminSidebar.jsx';
import CylinderDetail from '../../Component/CylinderDetail.jsx';
import PopupCard from '../../Component/PopupCard.jsx';
import PopupcardAdd from '../../Component/PopupcardAdd.jsx';
import PopupTransfer from '../../Component/PopupTransfer.jsx';
import {fetchCylinderData} from '../../api/inventory/inventoryApi.js';

const MainContent = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  // const [showCustomerCard, setShowCustomerCard] = useState(false);
  // const [customerName, setCustomerName] = useState('');
  // const [numberOfCylinders, setNumberOfCylinders] = useState(0);
  // const [showRentCard, setShowRentCard] = useState(false);
  // const [activeItem, setActiveItem] = useState('Exchange stock');
  // const [showForm, setShowForm] = useState(false);
  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [buttonType, setButtonType] = useState('rightArrow');
  const [popupType, setPopupType] = useState(null);
  const containerRef = useRef(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const fetchedItems = await fetchCylinderData();
  //     console.log(".........................",fetchedItems)
  //     setItems(fetchedItems);
  //   };
  //   fetchData();
  // }, [currentPage, searchQuery]);

  const refreshData = async () => {
    const fetchedItems = await fetchCylinderData();
    setItems(fetchedItems);
  };

  useEffect(() => {
    refreshData();
  }, [currentPage, searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  console.log(".............",items)

  const filteredItems = items.filter((item) =>
    item.gasType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

  const isSelected = (itemId) => {
    return items.find(item => item.id === itemId)?.selected || false;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSetActiveItem = (item) => {
    // setActiveItem(item);
    // setShowForm(true);
    setButtonType('rightArrow');

    if (item === 'Print Report') {
      setShowPopup(true);
      setPopupType('printReport');
    } else if (item === 'Add Cylinder') {
      setShowPopup(true);
      setPopupType('addCylinder');
    } else if (item === 'Transfer Cylinder') {
      setShowPopup(true);
      setPopupType('transferCylinder');
    } else {
      setShowPopup(false);
      setPopupType(null);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupType(null);
  };

  return (
    <div className="flex h-screen">
      <AdminSidebar
        setActiveItem={handleSetActiveItem}
        // setShowForm={setShowForm}
        setShowPopup={setShowPopup}
        status="available"
      />

      <div className="flex flex-col flex-grow">
        <div className="w-full flex-1 overflow-y-auto p-8" ref={containerRef}>
          <ul className="space-y-4">
            {paginatedItems.map((item) => (
              <li
                key={item.id}
                className={`relative flex justify-between items-center p-2 pl-8 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100 ${isSelected(item.id) ? 'font-bold bg-white' : ''}`}
                style={{ borderRadius: '0.5rem', backgroundColor: '#FFFFFF' }}
                onClick={() => setSelectedItem(item)}
              >
                <div
                  className="absolute left-0 top-0 bottom-0 w-5 rounded-l"
                  style={{ backgroundColor: item.color }}
                />
                <div className="flex flex-col">
                  <span className="mb-1 text-[#003366]">{item.gasType}</span>
                  <span className="text-sm text-gray-500">{item.quantity}</span>
                </div>
                <div className="ml-auto relative flex items-center justify-center" style={{ width: '1.5rem', height: '1.5rem' }}>
                  {buttonType === 'addExchange' || buttonType === 'addCustomer' || buttonType === 'checkbox' ? (
                    isSelected(item.id) ? <FiCheckSquare className="text-gray-500 w-6 h-6" /> : <FiSquare className="text-gray-500 w-6 h-6" />
                  ) : (
                    <FiChevronRight className="text-gray-500 w-6 h-6" />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        {selectedItem && (
          <CylinderDetail
            item={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        )}

        <div className="flex flex-col">
          <div className="flex justify-center items-center mt-4 p-2 bg-white space-x-20">
            <button
              className="px-4 py-1 bg-gray-200 rounded disabled:bg-gray-300"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-xs text-gray-600">
              Page {currentPage} of {Math.ceil(filteredItems.length / itemsPerPage)}, Showing {startIndex + 1} to {Math.min(startIndex + itemsPerPage, filteredItems.length)} of {filteredItems.length} items
            </span>
            <button
              className="px-4 py-1 bg-gray-200 rounded disabled:bg-gray-300"
              onClick={handleNextPage}
              disabled={startIndex + itemsPerPage >= filteredItems.length}
            >
              Next
            </button>
          </div>

          <hr className="mt-4 border-t border-gray-300" />
        </div>
      </div>

      {showPopup && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
          {popupType === 'printReport' && <PopupCard onClose={handleClosePopup} />}
          {popupType === 'addCylinder' && <PopupcardAdd onClose={handleClosePopup} refreshData={refreshData} />}
          {popupType === 'transferCylinder' && <PopupTransfer onClose={handleClosePopup} />}
        </>
      )}
    </div>
  );
};

export default MainContent;
