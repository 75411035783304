import React, { createContext, useContext, useState } from 'react';

const RentContext = createContext({
  rentData: [],
  addRentData: () => {}
});

export const RentProvider = ({ children }) => {
  const [rentData, setRentData] = useState([]);

  const addRentData = (data) => {
    setRentData(prevData => [...prevData, data]);  
  };

  return (
    <RentContext.Provider value={{ rentData, addRentData }}>
      {children}
    </RentContext.Provider>
  );
};

export const useRentContext = () => useContext(RentContext);
