import React, { useState, useEffect } from 'react';
import Sidebar from '../../Component/Sidebar.jsx';
import TopBar from '../../Component/TopBar.jsx';
import TableReceive from '../../Component/TableReceive.jsx';
import ExchangeContainer from '../../Component/ExchangeContainer.jsx';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const RecieveHistory = () => {
  const [activeItem, setActiveItem] = useState('Receive History');
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [buttonType, setButtonType] = useState('rightArrow');

  const handleSetActiveItem = (item) => {
    setActiveItem(item);
    setShowForm(false);
    // setButtonType('rightArrow');
  };

  const handleAddButtonClick = () => {
    setShowForm(true);
    // setButtonType('addExchange');
  };

  const [data] = useState([
    { ReceiveCylinder: 'Cylinder A1', customerName: 'John Doe', ReceiveDate: '2024-07-16' },
    { ReceiveCylinder: 'Cylinder A2', customerName: 'Jane Smith', ReceiveDate: '2024-07-17' },
    { ReceiveCylinder: 'Cylinder C1', customerName: 'Alice Johnson', ReceiveDate: '2024-07-18' },
    { ReceiveCylinder: 'Cylinder C2', customerName: 'Bob Brown', ReceiveDate: '2024-07-19' },
    { ReceiveCylinder: 'Cylinder E1', customerName: 'Charlie Davis', ReceiveDate: '2024-07-20' },
    { ReceiveCylinder: 'Cylinder E2', customerName: 'David Wilson', ReceiveDate: '2024-07-21' },
    { ReceiveCylinder: 'Cylinder G1', customerName: 'Emma Lee', ReceiveDate: '2024-07-22' },
    { ReceiveCylinder: 'Cylinder G2', customerName: 'Frank Miller', ReceiveDate: '2024-07-23' },
    { ReceiveCylinder: 'Cylinder I1', customerName: 'Grace Taylor', ReceiveDate: '2024-07-24' },
    { ReceiveCylinder: 'Cylinder I2', customerName: 'Henry Moore', ReceiveDate: '2024-07-25' },
  ]);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(item =>
        item.ReceiveCylinder.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.ReceiveDate.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    }
  }, [searchQuery, data]);

  const printPDF = () => {
    const input = document.getElementById('table-to-print');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      let position = heightLeft;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('table.pdf');
    });
  };

  return (
    <div className="relative flex h-screen">
      <Sidebar
        setActiveItem={handleSetActiveItem}
        setShowForm={handleAddButtonClick}
        // setButtonType={setButtonType}
        pageType="rent" // Pass pageType as 'exchange'
        activeItem={activeItem} // Pass activeItem prop
      />

      <div className="flex flex-col flex-grow overflow-y-auto">
        {!showForm && (
          <>
            <TopBar setSearchQuery={setSearchQuery} />
            <div className="flex-grow p-4">
              <button
                onClick={printPDF}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Print Table as PDF
              </button>
              <div id="table-to-print">
                <TableReceive data={filteredData} />
              </div>
            </div>
          </>
        )}
        {showForm && <ExchangeContainer />}
      </div>
    </div>
  );
};

export default RecieveHistory;
