import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FiChevronRight, FiCheckSquare, FiSquare } from 'react-icons/fi';
import AddCylinder from './AddCylinder';
import CylinderDetail from './CylinderDetail';
import CustomerCylindersCard from './CustomerCylindersCard';
import RentCylinderCard from './RentCylinderCard'; // Import the new component
import { colors } from '../Assets/Colors';
import cylinder from '../Assets/cylinder.png';
import axios from 'axios';

// Helper function to generate a 10-digit serial number with unique digits
const generateUniqueSerialNumber = () => {
  const digits = '0123456789'.split('');
  let serialNumber = '';

  while (serialNumber.length < 10) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    serialNumber += digits.splice(randomIndex, 1)[0]; // Remove used digit
  }

  return serialNumber;
};

const RentMainContent = ({ buttonType, onItemClick, searchQuery = '', selectedItems = [], showDetail = true, pageType }) => {
  const [items, setItems] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [showCustomerCard, setShowCustomerCard] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [numberOfCylinders, setNumberOfCylinders] = useState(0);
  const [showRentCard, setShowRentCard] = useState(false);
  const [loading, setLoading] = useState(false) 
  const [totalPages, setTotalPages] = useState(1);

  const containerRef = useRef(null);

  useEffect(() => {
    const rentCylinders = async() => {
      setLoading(true)
      try { 
        const response = await axios.get("https://aga.hancecybersecurity.com/api/Department/Rent/Inventory");
        if (Array.isArray(response.data)) {
          const updatedCylinders = response.data.map((customer) => ({
            ...customer,
            totalCylinders: customer.totalCylinders || 0,
          }));
          setItems(updatedCylinders);
          setTotalPages(Math.ceil(updatedCylinders.length / itemsPerPage));
        } else {
          throw new Error('unexpected error!')
        }
      }catch (error) {
        console.log('error', error)
      } finally{
        setLoading(false)
      }
    }

    rentCylinders()
  }, [])

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page on search query change
  }, [searchQuery]);

  useEffect(() => {
    // Scroll to top whenever currentPage changes
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = items.slice(startIndex, startIndex + itemsPerPage);

  const handleItemClick = (clickedItem) => {
    if (buttonType === 'addExchange' || buttonType === 'addCustomer' || buttonType === 'checkbox') {
      const updatedItems = items.map(item =>
        item.productId === clickedItem.productId
          ? { ...item, selected: !item.selected } // Toggle selection state only for the clicked item
          : item
      );
      setItems(updatedItems);
    } else {
      setSelectedItem(clickedItem);
      setCustomerName('Customer Name'); // Example customer name
      setNumberOfCylinders(5); // Example number of cylinders
      setShowCustomerCard(true); // Show customer card
      onItemClick(clickedItem);
    }
  };
  
  

  const isSelected = (itemId) => {
    return items.find(item => item.productId === itemId)?.selected || false;
  };

  const handleFormSubmit = (formData) => {
    const newItem = {
      id: items.length + 1,
      name: generateUniqueSerialNumber(),
      litters: formData.capacity,
      selected: false,
      image: cylinder,
      color: formData.color,
    };

    setItems([...items, newItem]);
    setShowForm(false);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRentButtonClick = () => {
    setShowRentCard(true); // Show RentCylinderCard
  };

  const onRent = (rentedItems) => {
    // Handle the rental process here
    console.log('Rented items:', rentedItems);
    setShowRentCard(false);
    // Additional rental logic can go here
  };

  const selectedCount = items.filter(item => item.selected).length;
  const checkedItems = items.filter(item => item.selected); // List of checked items

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-8" ref={containerRef}>
      {loading ? (
          <div>Loading...</div>
        ) : (
        <ul className="space-y-4">
          {paginatedItems.map((item) => (
            <li
              key={item.productId}
              className={`relative flex justify-between items-center p-2 pl-8 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100 ${isSelected(item.productId) ? 'font-bold bg-white' : ''}`}
              style={{ borderRadius: '0.5rem', backgroundColor: '#FFFFFF' }}
              onClick={() => handleItemClick(item)}
            >
              <div
                className="absolute left-0 top-0 bottom-0 w-5 rounded-l"
                style={{ backgroundColor: item.color }}
              />
              <div className="flex flex-col">
                <span className="mb-1 text-[#003366]">{item.serialNumber}</span> {/* Dark blue color for item name */}
                <span className="text-sm text-gray-500">{item.liters}</span>
              </div>
              <div className="ml-auto relative flex items-center justify-center" style={{ width: '1.5rem', height: '1.5rem' }}>
                {buttonType === 'addExchange' || buttonType === 'addCustomer' || buttonType === 'checkbox' ? (
                  isSelected(item.productId) ? <FiCheckSquare className="text-gray-500 w-6 h-6" /> : <FiSquare className="text-gray-500 w-6 h-6" />
                ) : (
                  <FiChevronRight className="text-gray-500 w-6 h-6" />
                )}
              </div>
            </li>
          ))}
        </ul>
        )}
      </div>

      <div className="flex flex-col">
        <div className="flex justify-center items-center mt-4 p-2 bg-white space-x-20">
          <button
            className="px-4 py-1 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-xs text-gray-600">
            Page {currentPage} of {Math.ceil(items.length / itemsPerPage)}, Showing {startIndex + 1} to {Math.min(startIndex + itemsPerPage, items.length)} of {items.length} items
          </span>
          <button
            className="px-4 py-1 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={handleNextPage}
            disabled={startIndex + itemsPerPage >= items.length}
          >
            Next
          </button>
        </div>

        {/* Horizontal Line */}
        <hr className="mt-4 border-t border-gray-300" />

        {pageType === 'rent' && (
          <div className="pl-8 pr-8 sticky bottom-0 bg-white z-10 shadow-md py-2">
            <div className="flex justify-between items-center p-4">
              <span className="text-lg font-bold">Selected Items: {selectedCount}</span>
              <button
                className="px-12 py-2 bg-[#4EA89E] text-white rounded hover:bg-blue-600"
                onClick={handleRentButtonClick}
              >
                Rent
              </button>
            </div>
          </div>
        )}
      </div>

      {showForm && <AddCylinder showForm={showForm} setShowForm={setShowForm} onSubmit={handleFormSubmit} />}
      {showCustomerCard && (
        <CustomerCylindersCard
          customerName={customerName}
          numberOfCylinders={numberOfCylinders}
          onClose={() => setShowCustomerCard(false)}
        />
      )}
      {showRentCard && (
        <RentCylinderCard
          items={checkedItems} // Pass the checked items as props
          onClose={() => setShowRentCard(false)} // Pass the onClose callback
          onRent={onRent} // Pass the onRent callback
        />
      )}
    </div>
  );
};

export default RentMainContent;
