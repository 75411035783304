import React, { useState, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import { colors } from '../Assets/Colors'; // Adjust the import path
import { fetchCylinderData } from '../api/inventory/inventoryApi'; // Import the API function

const MainContentTransfer = ({ buttonType, onItemClick, searchQuery = '' }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const data = await fetchCylinderData(); // Fetch data from the backend
      const mappedItems = data.map((item, index) => ({
        id: item.id, // Adjust these fields to match your backend response
        idNum: item.gasType, // Example mapping
        Numbers: `Liter ${item.quantity}`, // Adjust according to your data
        selected: false,
      }));
      setItems(mappedItems);
    };

    loadItems();
  }, []);

  const handleItemClick = (clickedItem) => {
    const updatedItems = items.map((item) =>
      item.id === clickedItem.id ? { ...item, selected: !item.selected } : item
    );
    setItems(updatedItems);

    // Pass the clicked item back to PopupTransfer
    onItemClick(clickedItem);
  };

  const handleCheckboxChange = (clickedItem) => {
    handleItemClick(clickedItem);
  };

  // Filter items based on the search query
  const filteredItems = items.filter(item =>
    item.idNum.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="px-4 py-1 flex flex-col">
      <ul className="space-y-4 flex-1">
        {filteredItems.map((item, index) => (
          <li
            key={item.id}
            className={`relative flex justify-between items-center p-2 pl-8 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100 ${item.selected ? 'font-bold bg-white' : ''}`}
            style={{ borderRadius: '0.5rem', backgroundColor: '#FFFFFF' }}
            onClick={() => handleItemClick(item)}
          >
            <div
              className="absolute left-0 top-0 bottom-0 w-5 rounded-l"
              style={{ backgroundColor: colors[index % colors.length] }}
            />
            <div className="flex flex-col">
              <span className="mb-1">{item.idNum}</span>
              <span className="text-sm text-gray-500">{item.Numbers}</span>
            </div>
            <label
              className="ml-auto relative cursor-pointer"
              style={{ width: '1.5rem', height: '1.5rem' }}
              onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the li element
            >
              <input
                type="checkbox"
                className="hidden"
                checked={item.selected}
                onChange={() => handleCheckboxChange(item)}
              />
              <div
                className={`absolute inset-0 flex items-center justify-center rounded border border-gray-300 ${item.selected ? 'bg-[#4EA89E]' : 'bg-white'}`}
              >
                {item.selected && <FiCheck className="text-white w-4 h-4" />}
              </div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MainContentTransfer;
