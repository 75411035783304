import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext'; // Adjust the import path if necessary
import axios from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); // Get the login function from context

  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState(''); // This will be the email
  const [password, setPassword] = useState(''); // This will be the phone number
  const [errors, setErrors] = useState({ username: '', password: '' });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://aga.hancecybersecurity.com/api/Auth/Users');
        if (Array.isArray(response.data)) {
          setUsers(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
  
    fetchUsers();
  }, []);
  
  


  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'username') {
      setUsername(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const validate = () => {
    let newErrors = { username: '', password: '' };
    let isValid = true;

    // Find the user by email
    const user = users.find((u) => u.email === username);
    console.log(user)

    if (!user) {
      newErrors.username = 'Email not found';
      isValid = false;
    } else if (user.phone !== password) { // Check phone number for password
      newErrors.password = 'Incorrect password';
      isValid = false;
    } else { 
      const userRole = user.roles[0];  
      login(userRole);  

      // Navigate based on user role
      if (userRole === 'exchange') {
        navigate('/exchange/exchangestock');
      } else if (userRole === 'rent') {
        navigate('/rent/rentcylinder');
      } else if (userRole === 'inventory') {
        navigate('/admin');
      } else if (userRole === 'sales') {
        navigate('/sales/salesdashboard');
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle successful validation inside validate
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-md w-full max-w-lg h-[350px]">
        <h2 className="text-4xl font-bold leading-tight text-black mb-6 text-center">
          <span 
            className="relative inline-block"
            style={{ borderBottom: '2px solid #4EA89E' }}
          >
            Welcome
          </span>
          <span className="ml-1">Back!</span>
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username (Email)
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring ${
                errors.username ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.username && (
              <p className="text-red-500 text-xs italic">{errors.username}</p>
            )}
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password (Phone Number)
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring ${
                errors.password ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.password && (
              <p className="text-red-500 text-xs italic">{errors.password}</p>
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="text-white font-bold py-2 px-6 rounded-lg w-full max-w-xs focus:outline-none focus:shadow-outline"
              style={{ backgroundColor: '#4EA89E' }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
