import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const StockCylinders = ({ onItemClick, searchQuery = '', clickedItems, selectedCapacity }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchStockCylinders = async () => {
      try {
        const response = await axios.get('https://aga.hancecybersecurity.com/api/Product');
        let allCylinders = response.data.filter(cylinder => !cylinder.customerId); 
        if (selectedCapacity) {
          allCylinders = allCylinders.filter(cylinder => cylinder.unit === selectedCapacity);
        }
        setItems(allCylinders);
      } catch (error) {
        console.error('Error fetching stock cylinders:', error);
      }
    };

    fetchStockCylinders();
  }, [selectedCapacity]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleItemClick = useCallback((clickedItem) => {
    onItemClick(clickedItem);
  }, [onItemClick]);

  // Ensure clickedItems is a Set
  const clickedItemsSet = new Set(clickedItems);

  // Filter out items that are in clickedItems
  const filteredItems = items
    .filter(item => item.serialNumber && item.serialNumber.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(item => !clickedItemsSet.has(item.id)); // Exclude clicked items

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedItems = filteredItems.slice(startIndex, startIndex + itemsPerPage); 

  return (
    <div className="relative flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4">
        <ul className="space-y-4">
          {paginatedItems.length > 0 ? (
            paginatedItems.map(item => (
              <li
                key={item.id}
                className={`relative flex justify-between items-center p-2 pl-4 pr-4 border border-gray-300 rounded cursor-pointer ${clickedItemsSet.has(item.id) ? 'bg-gray-200' : 'bg-white'} hover:bg-gray-100`}
                onClick={() => handleItemClick(item)}
              >
                <div
                  className="absolute top-0 left-0 h-full w-2"
                  style={{ backgroundColor: item.color || '#000' }}
                ></div>
                <div className="flex flex-col ml-4">
                  <span className="mb-1 text-[#003366]">Serial Number: {item.serialNumber || 'N/A'}</span>
                  <span className="text-sm text-gray-500">Capacity: {item.unit || 'N/A'}L</span>
                  <span className="text-sm text-gray-500">Gas Type: {item.gasType || 'N/A'}</span>
                </div>
              </li>
            ))
          ) : (
            <p className="text-center text-gray-500">No items match the search criteria.</p>
          )}
        </ul>
      </div>

      <div className="sticky bottom-0 left-0 right-0 bg-white border-t border-gray-300 flex justify-center py-4">
        <div className="flex items-center space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-xs text-gray-600">
            Page {currentPage} of {totalPages}, Showing {startIndex + 1} to {Math.min(startIndex + itemsPerPage, filteredItems.length)} of {filteredItems.length} items
          </span>
          <button
            className="px-4 py-2 bg-gray-200 rounded disabled:bg-gray-300"
            onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockCylinders;
