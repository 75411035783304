import React, { useState } from "react";
import Sidebar from "../Component/Sidebar"; 

const SalesPaymentInfo = () => {
  const [activeItem, setActiveItem] = useState("Payment Info");
  // const [showForm, setShowForm] = useState(false);
  // const [buttonType, setButtonType] = useState("checkbox");

  const data = [
    {
      id: "5146846548465",
      customerName: "Jane Cooper",
      serviceType: "fill",
      cylinderId: "5146846548465",
      status: "PAID",
    },
    {
      id: "5467319467348",
      customerName: "Wade Warren",
      serviceType: "test",
      cylinderId: "5467319467348",
      status: "UNPAID",
    },
    {
      id: "1345705945446",
      customerName: "Esther Howard",
      serviceType: "Exchange",
      cylinderId: "1345705945446",
      status: "PENDING",
    },
    // Add more rows as needed
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "PAID":
        return "bg-green-300";
      case "UNPAID":
        return "bg-red-300";
      case "PENDING":
        return "bg-yellow-300";
      default:
        return "";
    }
  };

  return (
    <div className="relative flex h-screen">
      <Sidebar
        setActiveItem={setActiveItem}
        // setShowForm={setShowForm}
        // setButtonType={setButtonType}
        pageType="sales"
        activeItem={activeItem}
      />
      <div className="flex flex-col flex-grow">
        <div className="bg-teal-500 text-white p-4 rounded-t-lg flex justify-between items-center">
          <h1 className="text-lg font-semibold">Payment Information</h1>
          <div className="flex space-x-4">
            <button className="bg-white text-teal-500 px-4 py-1 rounded">Pending</button>
            <button className="bg-white text-teal-500 px-4 py-1 rounded">Unpaid</button>
            <button className="bg-white text-teal-500 px-4 py-1 rounded">Paid</button>
            <button className="bg-white text-teal-500 px-4 py-1 rounded">All</button>
          </div>
        </div>
        <div className="overflow-y-auto p-6">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">ID</th>
                <th className="py-2 px-4 border-b text-left">Customer Name</th>
                <th className="py-2 px-4 border-b text-left">Service Type</th>
                <th className="py-2 px-4 border-b text-left">Cylinder ID</th>
                <th className="py-2 px-4 border-b text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{row.id}</td>
                  <td className="py-2 px-4 border-b">{row.customerName}</td>
                  <td className="py-2 px-4 border-b">{row.serviceType}</td>
                  <td className="py-2 px-4 border-b">{row.cylinderId}</td>
                  <td className={`py-2 px-4 border-b ${getStatusColor(row.status)}`}>
                    {row.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesPaymentInfo;
