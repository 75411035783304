import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../Component/Sidebar';
import TopBar from '../../Component/TopBar';
import Table from '../../Component/Table';
import ExchangeContainer from '../../Component/ExchangeContainer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer } from 'react-toastify';

const ExchangeHistory = () => {
  const [activeItem, setActiveItem] = useState('Exchange History');
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [buttonType, setButtonType] = useState('rightArrow');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch data from API
    axios.get('https://aga.hancecybersecurity.com/api/ProductExchange')
      .then(response => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(item =>
        item.customerCylinder.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.exchangeCylinder.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.exchangeDate.toLowerCase().includes(searchQuery.toLowerCase())
      ));
    }
  }, [searchQuery, data]);

  const handleSetActiveItem = (item) => {
    setActiveItem(item);
    setShowForm(false); // Hide the form when changing active item
    // setButtonType('rightArrow'); // Default to right arrow when changing active item
  };

  const handleAddButtonClick = () => {
    setShowForm(true); // Show the form when Add button is clicked
    // setButtonType('addExchange');
  };

  const printPDF = () => {
    const input = document.getElementById('table-to-print');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      let position = heightLeft;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('exchange-history.pdf');
    });
  };

  return (
    <div className="flex h-screen">
      <Sidebar
        setActiveItem={handleSetActiveItem}
        setShowForm={handleAddButtonClick}
        // setButtonType={setButtonType}
        pageType="exchange" // Pass pageType as 'exchange'
        activeItem={activeItem} // Pass activeItem prop
      />
      <div className="flex flex-col flex-grow overflow-y-auto">
        {!showForm && (
          <>
            <TopBar setSearchQuery={setSearchQuery} />
            <div className="flex-grow p-4">
              <button
                onClick={printPDF}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Print Table as PDF
              </button>
              <div id="table-to-print">
                <Table data={filteredData} />
              </div>
            </div>
          </>
        )}
        {showForm && <ExchangeContainer />}
      </div>
      
      <ToastContainer />
    </div>
  );
};

export default ExchangeHistory;
