import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from '../Context/AuthContext';

const ProtectedRoute = ({ element, ...rest }) => {
  const authCookie = Cookies.get('auth');
  const { isAuthenticated } = useAuth();

  return (isAuthenticated || authCookie) ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;