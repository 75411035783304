import React, { useState, useEffect } from 'react';
import AddCylinder from '../Component/AddCylinder';
import ExchangeCylinder from '../Component/ExchangeCylinder'; // Adjust the import path
import { FiPlus, FiX } from 'react-icons/fi';
// import { stockCylinders } from '../Assets/stockCylinders'; // Adjust the import path
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { useNavigate } from 'react-router-dom';

const ExchangeContainer = ({ selectedItem, label }) => {
  const navigate = useNavigate(); // Initialize navigate hook
  const [items1, setItems1] = useState([]);
  const [items2, setItems2] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showExchange, setShowExchange] = useState(false);
  // const [filteredStockCylinders, setFilteredStockCylinders] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  // const [selectedCylinderItem, setSelectedCylinderItem] = useState(null);
  const [clickedItems, setClickedItems] = useState(new Set());

  useEffect(() => {
    if (selectedItem && selectedItem.customerId) {
      // console.log('Fetching cylinders for customerId:', selectedItem.customerId);
      
      // Fetch customer cylinders from API
      axios.get(`https://aga.hancecybersecurity.com/api/Product/ByCustomers/${selectedItem.customerId}?serviceType=E`)
        .then(response => {
          // console.log('API Response:', response.data); // Log the whole response
          
          const cylinders = response.data?.cylinders || [];
          // console.log('Fetched cylinders:', cylinders);
  
          // Update items1 with fetched cylinders
          setItems1(cylinders);
  
          // Update items2 with placeholders
          setItems2(new Array(selectedItem.totalCylinders).fill(null));
        })
        .catch(error => {
          console.error('Error fetching customer cylinders:', error);
          setItems1([]);
          setItems2([]);
        });
    } else {
      // If no selectedItem or customerId, reset items1 and items2
      console.log('No selectedItem or customerId, resetting items.');
      setItems1([]);
      setItems2([]);
    }
  }, [selectedItem]);
  
  

  const handleItemClick = (item, index) => { 
    setSelectedIndex(index); 
    // setSelectedItem
    setShowExchange(true); // Ensure this is set to true to show the ExchangeCylinder
  };

  const handleItemRemove = (item, container, event) => {
    event.stopPropagation(); 
    const itemId = item.productId; // Use productId or the correct field
    
    // console.log('Item ID:', itemId);
    // console.log('Item:', item);
  
    if (container === 1) {
      // Find the index of the item in items1
      const itemIndexInItems1 = items1.findIndex(i => i && i.productId === itemId);
  
      if (itemIndexInItems1 !== -1) {
        // Remove item from items1
        const updatedItems1 = items1.filter((_, index) => index !== itemIndexInItems1);
        setItems1(updatedItems1);
  
        // Remove the item from items2 at the same index if it exists
        if (itemIndexInItems1 < items2.length) {
          const updatedItems2 = items2.filter((_, index) => index !== itemIndexInItems1);
          setItems2(updatedItems2);
        } else {
          console.warn('Index out of bounds for items2:', itemIndexInItems1);
        }
  
        // Update selectedIndex if necessary
        if (selectedIndex === itemIndexInItems1) {
          setSelectedIndex(null);
        } else if (selectedIndex > itemIndexInItems1) {
          setSelectedIndex(prevIndex => prevIndex - 1);
        }
      } else {
        console.warn('Item not found in items1.');
      }
    } else if (container === 2) {
      const itemId = item.id; // Use productId or the correct field 
      
      const itemIndexInItems2 = items2
        .findIndex(item => item !== null && item.id === itemId); // Find the index of the item
      // console.log('Index to be updated in items2:', itemIndexInItems2);
      
      if (itemIndexInItems2 !== -1) {
        // Set the item to null to indicate an empty slot
        const updatedItems2 = [...items2];
        updatedItems2[itemIndexInItems2] = null; // Set the specific item to null
        
        setItems2(updatedItems2);
        // Remove item from clickedItems
        setClickedItems(prev => {
          const newClickedItems = new Set(prev);
          newClickedItems.delete(itemId);
          return newClickedItems;
        });
      } else {
        console.warn('Item not found in items2.');
      }
    } else {
      console.warn('Invalid container specified for removal.');
    }
    
  };
   
  const toggleForm = () => {
    setShowForm(prev => !prev);
  };

  const onSubmitAddCylinder = (formData) => {
    const newCylinder = {
      id: items1.length + 1,
      serialNumber: formData.serialNumber,
      capacity: formData.capacity,
      color: formData.color,
      image: formData.image,
      gasType: formData.gasType,
      selected: false,
    };
    setItems1(prevItems => [...prevItems, newCylinder]);
    setItems2(prevItems2 => [...prevItems2, null]); // Add a corresponding slot in items2
  };

  const handleAddExchangeItems = (exchangeItems) => {
    // console.log('Adding exchange items:', exchangeItems);

    if (selectedIndex !== null) {
      // Create a copy of items2 to manipulate
      const updatedItems2 = [...items2];
      // Ensure that updatedItems2 has at least selectedIndex length
      while (updatedItems2.length <= selectedIndex) {
        updatedItems2.push(null); // Placeholder for positions that don't yet exist
      }

      // Place the exchange items starting from the selectedIndex
      exchangeItems.forEach((item, index) => {
        const position = selectedIndex + index;

        // Insert or replace item at the specific position
        if (position < updatedItems2.length) {
          updatedItems2[position] = item; // Replace existing item
        } else {
          updatedItems2.push(item); // Add item at the end if position is out of bounds
        }
      });

      // Optionally: Limit the length of items2 to match items1
      if (updatedItems2.length > items1.length) {
        updatedItems2.length = items1.length; // Truncate the list to match items1 length
      }

      setItems2(updatedItems2); // Update state with new items2// Update clickedItems
      setClickedItems(new Set(updatedItems2.map(item => item?.id).filter(id => id !== undefined)));
    }
    setShowExchange(false); // Close the exchange form
    // setClickedItems(prev => new Set([...prev, ...exchangeItems.map(item => item.id)]));
  };

  const isSubmitDisabled = () => {
    // Check if items2 contains only nulls for empty slots
    const hasEmptySlots = items2.some(item => item === null);
    // Check if items1 and items2 have the same length
    const areItemsEqual = items1.length === items2.filter(item => item !== null).length;

    return !areItemsEqual || hasEmptySlots; // Disable submit if items are not equal or have empty slots
  };

  const handleSubmit = () => {
    // Prepare the data to send
    const customerSerials = items1.map(item => ({ customerSerial: item.serialNumber }));
    const exchangeSerials = items2.filter(item => item !== null).map(item => ({ exchangeSerial: item.serialNumber }));

    // Construct the payload
    const payload = {
      customerId: selectedItem.customerId || 0, // Use selectedItem.customerId or default to 0
      exchangeDate: new Date().toISOString(), // Current date and time in ISO format
      customerSerials,
      exchangeSerials
    };

    // Post the data
    axios.post('https://aga.hancecybersecurity.com/api/ProductExchange', payload)
      .then(response => {
        // Show success toast message
        toast.success('Data successfully submitted!');

        // Navigate to ExchangeHistory
        navigate('/exchange/exchangehistory'); // Adjust the path if needed
      })
      .catch(error => {
        // Show error toast message
        toast.error('Error submitting data.');
      });
  }; 
  
  return (
    <div className="flex flex-col items-center pt-8 h-screen">
      <div className="flex flex-row justify-center items-start space-x-10 w-full mb-6 pl-20 pr-10">
        {/* Customer Cylinder */}
        <div className="flex flex-col bg-[#CDCDCD] rounded-lg shadow-xl w-full max-w-2xl overflow-hidden h-[calc(100vh-130px)] relative">
          <h1 className="text-2xl font-bold mb-4 bg-[#4EA89E] text-white p-4 w-full text-center sticky top-0 z-0">
            {label || 'Customer Cylinder'}
          </h1>
          <div className="flex flex-col bg-[#CDCDCD] pl-4 pr-4 h-full">
            <ul className="flex-1 overflow-y-auto rounded-lg pr-2 mb-20 pb-4">
              {items1.length > 0 ? (
                items1.map((item, index) => (
                  <li
                    key={`items1-${item.id}-${index}`} // Ensure uniqueness with combination of id and index
                    className="relative bg-white shadow-lg flex justify-between items-center mt-2 p-1 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100"
                    onClick={() => handleItemClick(item, index)}
                  >
                    <div
                      className="absolute top-0 left-0 h-full w-2"
                      style={{ backgroundColor: item.color || '#000' }}
                    ></div>
                    <div className="flex items-center ml-2">
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Serial Number: {item.serialNumber}</span>
                        <span className="text-sm text-gray-500">Capacity: {item.capacity}L</span>
                        <span className="text-sm text-gray-500">Gas Type: {item.gasType}</span>
                      </div>
                    </div>
                    <button
                      onClick={(event) => handleItemRemove(item, 1, event)}
                      className="text-white p-2 absolute right-0 top-0 bottom-0 w-12 bg-red-300 rounded-r flex items-center justify-center"
                      style={{ backgroundColor: '#FF9E9E' }}
                    >
                      <FiX className="text-4xl" />
                    </button>
                  </li>
                ))
              ) : (
                <p className="text-center text-gray-500">No cylinders available.</p>
              )}
            </ul>
            <div className="absolute bottom-4 right-4">
              <button
                onClick={toggleForm}
                className="bg-[#4EA89E] text-white p-4 rounded-full shadow-lg hover:bg-blue-600"
              >
                <FiPlus className="text-2xl" />
              </button>
            </div>
          </div>
        </div>
        {/* Warehouse Cylinder */}
        <div className="flex flex-col bg-[#CDCDCD] rounded-lg shadow-xl w-full max-w-2xl overflow-hidden h-[calc(100vh-130px)] relative">
          <h1 className="text-2xl font-bold mb-4 bg-[#4EA89E] text-white p-4 w-full text-center sticky top-0 z-0">
            Warehouse Cylinder
          </h1>
          <div className="flex flex-col bg-[#CDCDCD] pl-4 pr-4 h-full">
            <ul className="flex-1 overflow-y-auto rounded-lg pr-2">
              {items2.length > 0 ? (
                items2.map((item, index) => (
                  item ? ( // Check if the item is not null
                    <li
                      key={`items2-${item.id}-${index}`} // Ensure uniqueness with combination of id and index
                      className="relative bg-white shadow-lg flex justify-between items-center mt-2 p-1 pr-4 border border-gray-300 rounded cursor-pointer hover:bg-gray-100"
                    >
                      <div
                        className="absolute top-0 left-0 h-full w-2"
                        style={{ backgroundColor: item.color || '#000' }}
                      ></div>
                      <div className="flex items-center ml-2">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-500">Serial Number: {item.serialNumber}</span>
                          <span className="text-sm text-gray-500">Capacity: {item.capacity}L</span>
                          <span className="text-sm text-gray-500">Gas Type: {item.gasType}</span>
                        </div>
                      </div>
                      <button
                        onClick={(event) => handleItemRemove(item, 2, event)}
                        className="text-white p-2 absolute right-0 top-0 bottom-0 w-12 bg-red-300 rounded-r flex items-center justify-center"
                        style={{ backgroundColor: '#FF9E9E' }}
                      >
                        <FiX className="text-4xl" />
                      </button>
                    </li>
                  ) : (
                    <li key={`empty-slot-${index}`} className="p-6 relative bg-gray-200 shadow-lg flex justify-center items-center mt-2 p-1 pr-4 border border-gray-300 rounded cursor-default">
                      <span className="text-sm text-gray-500">Empty Slot</span>
                    </li>
                  )
                ))
              ) : (
                <p className="text-center text-gray-500">No items in the warehouse.</p>
              )}
            </ul>
          </div>
        </div>
      </div>
      
      <ToastContainer />

      <div className="w-full flex justify-end px-10">
        <button
          className={`bg-blue-500 text-white px-12 py-2 rounded mt-2 ${isSubmitDisabled() ? 
            'bg-gray-400 cursor-not-allowed' : 
            'bg-[#4EA89E] hover:bg-blue-600'} text-white`}
          disabled={isSubmitDisabled()}
        >
          <span onClick={handleSubmit} className="text-xl text-center">Submit</span>
        </button>
      </div>


      {showForm && <AddCylinder showForm={toggleForm} setShowForm={setShowForm} onSubmit={onSubmitAddCylinder} />}

      {showExchange && (
        <ExchangeCylinder
          showForm={showExchange} 
          setShowForm={setShowExchange} 
          clickedItems={clickedItems}
          addExchangeItems={handleAddExchangeItems}  
        />
      )}
    </div>
  );
};

export default ExchangeContainer;