import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if there's a cookie indicating authentication
    const authCookie = Cookies.get('auth');
    setIsAuthenticated(!!authCookie);
  }, []);

  const login = (userRole) => {
    Cookies.set('auth', userRole, { expires: 7 }); // Cookie expires in 7 days
    setIsAuthenticated(true);
  };

  const logout = () => {
    Cookies.remove('auth');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
