import React, { useState } from 'react';
import { FiX } from 'react-icons/fi'; // Import FiX icon for close button

const AddRentCustomerCard = ({ onClose }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = () => {
    // Handle the form submission logic here
    console.log('Name:', name);
    console.log('Phone Number:', phoneNumber);
    onClose(); // Close the card after submission
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-[#F1F1F1] w-[90vw] max-w-2xl p-8 rounded shadow-lg"> {/* Changed background color */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Add Customer</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-900">
            <FiX className="w-6 h-6" /> {/* Close icon */}
          </button>
        </div>

        <div className="space-y-4">
          <div className="mb-4">
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              className="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              className="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-20 flex justify-end">
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-[#4EA89E] text-white rounded hover:bg-blue-600"
          >
            Add Customer {/* Button text */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRentCustomerCard;
