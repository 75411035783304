import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import StockCylinders from './StockCylinders'; // Adjust the import path

const ExchangeCylinder = ({ showForm, setShowForm, clickedItems, addExchangeItems, filteredExchangeItems }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCapacity, setSelectedCapacity] = useState(null);

  if (!showForm) return null;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleItemClick = (item) => {
    setSelectedCapacity(item.unit); // Store the selected item's capacity
    addExchangeItems([item]); // Pass the selected item to the parent component
    // setShowForm(true); // Ensure this is set to true to show the ExchangeCylinder
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 transition-opacity duration-300 z-50">
      <div className="relative bg-white rounded shadow-lg max-w-3xl w-full max-h-[90vh] mx-auto overflow-hidden">
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 transition-transform transform hover:scale-110 focus:outline-none focus:ring focus:ring-gray-300 rounded-full p-1"
          onClick={() => setShowForm(false)}
          style={{ zIndex: 20 }}
        >
          <FiX className="text-4xl" />
        </button>

        <div className="sticky top-0 bg-white border-b border-gray-300 z-10">
          <h2 className="text-3xl font-bold mb-4 text-center py-4">Select Cylinder</h2>
          <div className="px-4 py-2 border-b border-gray-300">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="overflow-y-auto max-h-[calc(90vh-168px)]">
          <StockCylinders 
            onItemClick={handleItemClick}
            searchQuery={searchQuery}
            clickedItems={clickedItems} // Ensure this is updated correctly
            selectedCapacity={selectedCapacity} // Pass the selected capacity
          />
        </div>
      </div>
    </div>
  );
};

export default ExchangeCylinder;
