import React, { useState, useEffect } from 'react';
import MainContentTransfer from './MainContentTransfer.jsx'; 
import { submitTransferCylinderFormData, fetchDepartmentData } from '../api/inventory/inventoryApi'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

const PopupTransfer = ({ onClose, searchQuery }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [departments, setDepartments] = useState([]); 
  const [selectedDepartment, setSelectedDepartment] = useState(null); 
  const [selectedItems, setSelectedItems] = useState([]); 

  useEffect(() => {
    const loadDepartments = async () => {
      try {
        const departmentData = await fetchDepartmentData();
        const filteredDepartments = departmentData.filter(department => 
          ['Rent', 'Lend', 'Exchange'].includes(department.name)
        );
        setDepartments(filteredDepartments); 
      } catch (error) {
        console.error('Failed to fetch departments:', error);
      }
    };

    loadDepartments();
  }, []);

  const toggleDropdownVisibility = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    console.log("Selected Department:", option);
    setSelectedDepartment(option);
    setIsDropdownVisible(false);
};


  const handleItemClick = (item) => {
    setSelectedItems(prevItems => {
      const isSelected = prevItems.some(selectedItem => selectedItem.id === item.id);
      if (isSelected) {
        return prevItems.filter(selectedItem => selectedItem.id !== item.id);
      } else {
        return [...prevItems, item];
      }
    });
  };

  const handleTransfer = async () => {
    if (selectedItems.length === 0) {
      toast.error('Please select at least one cylinder to transfer.');
      return;
    }
  
    if (!selectedDepartment) {
      toast.error('Please select a department.');
      return;
    }
  
    try {
      const cylinderIds = selectedItems.map(item => item.id);
      console.log("............",typeof selectedDepartment.departmentId)
  
      const formData = {
        departmentId: parseInt(selectedDepartment.departmentId), 
        productIds: cylinderIds, 
      };
  
      console.log("Form Data:", formData);
  
      const result = await submitTransferCylinderFormData(formData);
  
      if (result.errors && result.errors.length > 0) {
        const errorMessage = result.errors[0].errorMessage;
        toast.error(`Transfer failed: ${errorMessage}`);
      } else {
        toast.success('Cylinders transferred successfully!');
        onClose(); 
      }
    } catch (error) {
      toast.error('Failed to transfer cylinders. Please try again.');
    }
  };
  
  
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="bg-gray-200 p-6 rounded-lg  w-3/4 h-4/5 max-w-4xl mx-auto relative flex flex-col">
        <h2 className="text-2xl font-semibold mb-2">Transfer Cylinder</h2>
        <button
          className="absolute top-4 right-8 w-10 h-10 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &#10005;
        </button>
        <div className="mt-1 h-3/4 overflow-auto">
          <div className="rounded-lg ">
            <MainContentTransfer 
              searchQuery={searchQuery} 
              onItemClick={handleItemClick} 
              buttonType="rightArrow" 
            />
          </div>
        </div>
        <div className="flex pr-4 ml-72">
          <div className="relative w-1/4 top-9 left-32">
            <div className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
              onClick={toggleDropdownVisibility}
            >
              {selectedDepartment ? selectedDepartment.name : 'Department'}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                </svg>
              </div>
            </div>
            {isDropdownVisible && (
              <div className="absolute bottom-full w-full mb-2 bg-white border border-gray-300 rounded shadow">
                {departments.map(department => (
                  <div
                    key={department.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleOptionClick(department)}
                  >
                    {department.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            className="absolute bottom-4 right-4 bg-[#4EA89E] text-white py-2 px-4 w-1/4 rounded-xl hover:bg-blue-600"
            onClick={handleTransfer}
          >
            Transfer Cylinder
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PopupTransfer;
