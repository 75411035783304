import React, { useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import axios from 'axios';

const PhoneNumberSearch = ({ onCustomerSelect }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customers, setCustomers] = useState([]); 

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value) {
      try {
        const response = await axios.get(`https://aga.hancecybersecurity.com/api/Customer/Phone/Search?phone=${value}`);

        if (response.data && response.data.length > 0) {
          setCustomers(response.data);  
        } else {
          setCustomers([{ name: 'No customer found' }]);  
        }
        setIsDialogVisible(true); 
      } catch (error) {
        console.error('Error fetching customer data:', error);
        setCustomers([{ name: 'No customer found' }]); 
        setIsDialogVisible(true);  
      }
    } else {
      setIsDialogVisible(false); 
      setCustomers([]);  
    }
  };

  const handleCustomerClick = (customer) => {
    if (customer.name !== 'No customer found') {
      onCustomerSelect(customer);
      console.log(customer)
      setPhoneNumber('');  
      setIsDialogVisible(false);  
    }
  };

  return (
    <div className="p-2 pl-56 justify-center items-center sticky top-0 bg-white z-10">
      <div className="w-full flex items-center">
        <div className="relative flex w-full">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <SearchIcon className="h-5 w-5 text-gray-400" />
          </span>
          <input
            type="text"
            placeholder="Phone Number"
            className="w-full pl-8 pr-12 py-2 border border-gray-300 rounded-l rounded-r-none focus:outline-none"
            value={phoneNumber}
            onChange={handleInputChange}
          />
        </div>
        {isDialogVisible && (
          <div className="absolute top-16 left-16 right-0 mx-auto w-80 bg-white border border-gray-300 rounded shadow-lg p-4">
            {customers.map((customer, index) => (
              <button
                key={index}
                className="block text-gray-800 text-xl font-semibold mb-4 px-6 py-3 bg-gray-100 hover:bg-gray-200 transition duration-200 focus:outline-none w-full text-left"
                onClick={() => handleCustomerClick(customer)}
              >
                {customer.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberSearch;
