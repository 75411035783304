import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sidebarData } from '../Assets/PagesData';

const Sidebar = ({ setShowForm, setActiveItem, pageType, activeItem }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profileImg, profileName, isAvailable, items, buttons } = sidebarData[pageType] || {};

  // Determine active item based on the current URL path
  const currentActiveItem = items.find(item => location.pathname.includes(item.path))?.name;

  const handleItemClick = (item, path) => {
    setActiveItem(item); // Set active item
    navigate(path); // Navigate to the selected path
  };

  const handleButtonClick = (action) => {
    if (action === 'logout') {
      navigate('/login');
    } else {
      setShowForm(action);
    }
  };

  return (
    <div className="relative bg-gray-300 w-72 h-screen flex flex-col p-4">
      <div className="mb-14 mt-4 relative">
        <div className="relative w-24 h-24 mx-auto">
          <img 
            src={profileImg} 
            alt="User Profile" 
            className="w-full h-full rounded-full"
          />
          <div
            className={`absolute bottom-0 right-0 w-4 h-4 rounded-full border-2 border-white ${
              isAvailable ? 'bg-green-500' : 'bg-yellow-500'
            }`}
          ></div>
        </div>
        <h1 className="text-xl font-medium mt-4 text-center">{profileName}</h1>
      </div>
      <div className="flex-grow flex flex-col">
        <ul className="space-y-2 flex flex-col items-center justify-center mb-4">
          {items.map((item) => (
            <li className="w-full" key={item.name}>
              <button 
                onClick={() => handleItemClick(item.name, item.path)}
                className={`relative block p-2 text-xl text-gray-700 hover:text-gray-900 ${currentActiveItem === item.name ? 'font-bold' : ''}`}
              >
                <span className={`${currentActiveItem === item.name ? 'relative' : ''}`}>
                  {item.name}
                  {currentActiveItem === item.name && (
                    <span className="absolute left-0 bottom-0 w-full border-b-2 border-green-500" style={{ left: '0', bottom: '-0.1rem' }} />
                  )}
                </span>
              </button>
            </li>
          ))}
        </ul>

        <div className="flex flex-col items-center mt-4">
          {buttons.filter(button => button.text !== 'Log out').map((button) => (
            <button
              key={button.text}
              className="mb-2 px-4 py-2 bg-[#4EA89E] text-white hover:bg-blue-600 rounded w-40 text-center"
              onClick={() => handleButtonClick(button.action)}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>

      <div className="w-full mt-auto">
        <div className="p-4">
          <button 
            className="bg-[#D9D9D9] text-black py-2 px-4 rounded-lg w-full flex items-center justify-center"
            onClick={() => handleButtonClick('logout')}
          >
            <span className="text-xl">{'[-> Log out'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
