export const submitRentData = async (formData) => {
    try {
        console.log('Form Data Submitted:', formData);
      const response = await fetch('https://aga.hancecybersecurity.com/api/Product/Rent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  };
  