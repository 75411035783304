
export const submitFormData = async (formData) => {
    try {
        console.log('Form Data Submitted:', formData);
      const response = await fetch('https://aga.hancecybersecurity.com/api/Product', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([formData]),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  };
  
  export const fetchCylinderData = async () => {
  
  
    try {
      const response = await fetch(`https://aga.hancecybersecurity.com/api/Product`);
  
      
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Error ${response.status}: ${errorMessage}`);
      }
  
      const data = await response.json();
      return data  || []; 
  
    } catch (error) {
      console.error('Error fetching paginated data:', error);
      return [];
    }
  };

  export const submitTransferCylinderFormData = async (formData) => {
    try {
      const response = await fetch('https://aga.hancecybersecurity.com/api/Department/Inventory/Move', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      
      console.log("llllllllllllllllll")
  
      return await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
      throw error;
    }
  };
  export const fetchDepartmentData = async () => {
  
  
    try {
      const response = await fetch(`https://aga.hancecybersecurity.com/api/Department`);
  
      
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Error ${response.status}: ${errorMessage}`);
      }
  
      const data = await response.json();
      return data  || []; 
  
    } catch (error) {
      console.error('Error fetching paginated data:', error);
      return [];
    }
  };
  