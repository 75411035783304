import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { submitFormData } from '../api/inventory/inventoryApi'; // Import the API call function
import { useNavigate } from 'react-router-dom';

const PopupcardAdd = ({ onClose ,refreshData}) => { 
  const [formData, setFormData] = useState({
    serialNumber: '',
    unit: '',
    gasType: '',
    quantity: 0,
    pressure: '',
    color: ''
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? e.target.checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await submitFormData(formData);
      
      // Show the success toast message for 5 seconds
      toast.success('Form submitted successfully!', {
        autoClose: 5000, // Ensure the toast stays for 5 seconds
        onClose: () => navigate('/admin') // Navigate to /admin after toast closes
      });
  
      // Reset form data
      setFormData({
        serialNumber: '',
        unit: '',
        gasType: '',
        quantity: 0,
        pressure: '',
        color: ''
      });
  
      refreshData();
      onClose(); // Close the popup form
      
      // Navigate to the /admin page
      navigate('/admin');
  
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('There was an error submitting the form. Please try again.');
    } finally {
      setLoading(false); // Reset loading to false when the submission is complete
    }
  };
  
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 h-full z-40">
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg h-2/3 w-3/4 max-w-4xl mx-auto relative flex flex-col h-[80%]">
        <button
          className="absolute top-4 right-8 w-10 h-10 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &#10005;
        </button>
        <h2 className="text-2xl font-semibold mb-4">Add Cylinder</h2>
        <form className="flex flex-col space-y-4 flex-grow">
          <div className="flex flex-col space-y-2">
            {/* Inner Card for Field 1 */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <input
                type="text"
                id="serialNumber"
                name="serialNumber"
                placeholder='Serial Number'
                value={formData.serialNumber}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            {/* Inner Card for Field 2 */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <input
                type="text"
                id="quantity"
                name="quantity"
                placeholder='Liter'
                value={formData.quantity}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            {/* Inner Card for Field 3 */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <input
                type="text"
                id="color"
                name="color"
                placeholder='Color'
                value={formData.color}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="flex flex-col space-y-2 flex-grow mb-2">
            {/* Inner Card for Combo Box */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <select
                id="comboBox"
                name="gasType"
                placeholder='Gas Type'
                value={formData.gasType}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="" disabled className="text-gray-400">Gas Type</option> 
                <option value="Oxygen">Oxygen</option>
                <option value="Nitrogen">Nitrogen</option>
                <option value="Acetylene">Acetylene</option>
                <option value="Compressed">Compressed</option>
                <option value="air">air</option>
                <option value="Argon">Argon</option>
              </select>
            </div>
          </div>
          {/* Adjust the bottom margin to decrease the gap */}
          <div className="flex-grow"></div>
        </form>
        <button
          className="absolute bottom-4 right-4 bg-[#4EA89E] text-white py-2 px-4 w-1/4 rounded-xl hover:bg-blue-600"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Done'}
        </button>
      </div>
    </div>
  );
};

export default PopupcardAdd;
