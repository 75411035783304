import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SalesMainContent = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://aga.hancecybersecurity.com/api/Products/Sales');
        setData(response.data); // Assuming response.data is the array of sales data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once on component mount

  const getStatusColor = (status) => {
    switch (status) {
      case "PAID":
        return "bg-green-300";
      case "UNPAID":
        return "bg-red-300";
      case "PENDING":
        return "bg-yellow-300";
      default:
        return "";
    }
  };

  const handleRowClick = (id) => {
    navigate(`/salespaymentinfo/${id}`);
  };

  return (
    <div className="flex justify-center p-6">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">Customer Name</th>
            <th className="py-2 px-4 border-b text-left">Requested Service</th>
            <th className="py-2 px-4 border-b text-left">Quantity</th>
            <th className="py-2 px-4 border-b text-left">Amount (Birr)</th>
            <th className="py-2 px-4 border-b text-left">Date</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} onClick={() => handleRowClick(row.id)} className="cursor-pointer">
              <td className="py-2 px-4 border-b">{row.customerName}</td>
              <td className="py-2 px-4 border-b">{row.requestedService}</td>
              <td className="py-2 px-4 border-b">{row.quantity}</td>
              <td className="py-2 px-4 border-b">{row.amount}</td>
              <td className="py-2 px-4 border-b">{row.date}</td>
              <td className={`py-2 px-4 border-b ${getStatusColor(row.status)}`}>
                {row.status}
              </td>
              <td className="py-2 px-4 border-b text-center">
                <button className="mr-2 bg-green-500 text-white px-4 py-1 rounded">Approve</button>
                <button className="bg-red-500 text-white px-4 py-1 rounded">Reject</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesMainContent;
