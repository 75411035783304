import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

const CustomerCylindersCard = ({ customerName, numberOfCylinders, cylinders, onClose, onAddToExchange }) => {
  const [selectedCylinders, setSelectedCylinders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (cylinder) => {
    setSelectedCylinders(prevSelected => {
      const isSelected = prevSelected.includes(cylinder);
      console.log('Cylinder before toggle:', cylinder, 'Selected:', isSelected); // Debugging
      if (isSelected) {
        return prevSelected.filter(item => item !== cylinder);
      } else {
        return [...prevSelected, cylinder];
      }
    });
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedCylinders(isChecked ? cylinders : []);
  };

  const handleAddToExchange = () => {
    console.log('Selected Cylinders for Exchange:', selectedCylinders); // Should show the selected cylinders
    onAddToExchange(selectedCylinders); // Pass selected cylinders to the parent component
    onClose(); // Close the card after adding
  };
  

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50">
      <div className="relative bg-[#F1F1F1] rounded-lg shadow-lg p-4 w-[80vw] max-w-3xl h-[90vh] flex flex-col">
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 transition-transform transform hover:scale-110 focus:outline-none focus:ring focus:ring-gray-300 rounded-full p-1 z-30"
          onClick={onClose}
        >
          <FiX className="text-2xl" />
        </button>

        <h2 className="text-3xl font-bold mb-6 text-center">{customerName}</h2>

        <div className="flex flex-col space-y-6 p-4 mb-4 bg-white flex-grow">
          <div className="flex flex-col bg-[#CDCDCD] rounded-lg shadow-xl flex-grow">
            <div className="flex items-center justify-between p-4">
              <h3 className="text-lg font-semibold">Number of Cylinders {numberOfCylinders}</h3>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="form-checkbox h-6 w-6 text-[#4EA89E]"
                />
                <label className="ml-2 text-lg font-medium">Select All</label>
              </div>
            </div>
            <div className="flex flex-col bg-[#CDCDCD] p-4 flex-grow overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
              <ul className="flex-1">
                {cylinders.length > 0 ? (
                  cylinders.map((cylinder, index) => (
                    <li
                      key={index}
                      onClick={() => handleCheckboxChange(cylinder)}
                      className={`relative flex items-center mt-2 p-4 border border-gray-300 rounded cursor-pointer ${
                        selectedCylinders.includes(cylinder)
                          ? 'bg-green-100'
                          : 'bg-white hover:bg-gray-100'
                      }`}
                    >
                      <div
                        className="absolute top-0 left-0 h-full w-2"
                        style={{ backgroundColor: cylinder.color || '#000' }}
                      ></div>
                      <div className="flex-grow flex items-center ml-2 space-x-4">
                        <span><strong>Serial Number:</strong> {cylinder.serialNumber}</span>
                        <span><strong>Capacity:</strong> {cylinder.capacity} liters</span>
                        <span><strong>Gas Type:</strong> {cylinder.gasType}</span>
                      </div>
                      <input
                        type="checkbox"
                        checked={selectedCylinders.includes(cylinder)}
                        onChange={() => handleCheckboxChange(cylinder)}
                        className="ml-auto mr-4 form-checkbox h-6 w-6 text-[#4EA89E]"
                      />
                    </li>
                  ))
                ) : (
                  <li className="text-gray-700">No cylinders available</li>
                )}
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center mt-4">
            <button
              onClick={handleAddToExchange}
              className="px-4 py-2 bg-[#4EA89E] text-white rounded hover:bg-[#3b8b73] transition-colors duration-200"
            >
              Add to Exchange
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCylindersCard;
