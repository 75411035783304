// src/pages/RentHistory.js
import React from 'react';
import Sidebar from '../../Component/Sidebar'; 
import TopBar from '../../Component/TopBar';
import RentHistoryTable from '../../Component/RentHistoryTable';
import { useRentContext } from '../../Context/RentContext';

const RentHistory = () => {
  const { rentData } = useRentContext(); // Use context to get data

  console.log("Rent History Data:", rentData); // Debugging line

  return (
    <div className="flex h-screen">
      <Sidebar
        setActiveItem={() => {}}
        setShowForm={() => {}}
        setButtonType={() => {}}
        pageType="rent"
        activeItem="Rent History"
      />
      <div className="flex flex-col flex-grow overflow-y-auto">
        <TopBar />
        <div className="flex-grow p-4">
          <RentHistoryTable data={rentData} />
        </div>
      </div>
    </div>
  );
};

export default RentHistory;
