import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ExchangeStock from './Pages/Exchange/ExchangeStock';
import Login from './Pages/Login';
import ExchangeHistory from './Pages/Exchange/ExchangeHistory';
import RecieveExchangeHistory from './Pages/Exchange/ReceiveHistory';
import RentHistory from './Pages/Rent/RentHistory';
import RecieveRentHistory from './Pages/Rent/ReceiveHistory';
import Rent from './Pages/Rent/Rent';
import MainContent from './Pages/Inventory/Admin';
import PrintReport from './Pages/Inventory/PrintReport';
import { RentProvider } from './Context/RentContext';
import AnalyticsMainContent from './Pages/Inventory/Analytics';
import { AuthProvider } from './Context/AuthContext'; // Adjust the import path if necessary
import ProtectedRoute from './Component/ProtectedRoute'; // Adjust the import path if necessary
import { ToastContainer } from 'react-toastify';
import Sales from './Pages/Sales/Sales';
import SalesPaymentInfo from './Component/SalesPaymentInfo';

const App = () => {
  return (
    <AuthProvider>
      <RentProvider>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover draggable pauseOnFocusLoss/>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/admin" element={<ProtectedRoute element={<MainContent />} />} />
            <Route path="/exchange/exchangestock" element={<ProtectedRoute element={<ExchangeStock />} />} />
            <Route path="/exchange/exchangehistory" element={<ProtectedRoute element={<ExchangeHistory />} />} />
            <Route path="/exchange/receivehistory" element={<ProtectedRoute element={<RecieveExchangeHistory />} />} />
            <Route path="/admin/analytics" element={<ProtectedRoute element={<AnalyticsMainContent />} />} />
            <Route path="/rent/receivehistory" element={<ProtectedRoute element={<RecieveRentHistory />} />} />
            <Route path="/report" element={<ProtectedRoute element={<PrintReport />} />} />
            <Route path="/rent/rentcylinder" element={<ProtectedRoute element={<Rent />} />} />
            <Route path="/rent/renthistory" element={<ProtectedRoute element={<RentHistory />} />} />
            <Route path="/sales/salesdashboard" element={<ProtectedRoute element={<Sales />} />} />
            <Route path="/salespaymentinfo/:id" element={<SalesPaymentInfo />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </RentProvider>
    </AuthProvider>
  );
};

export default App;
