import React, { useState } from 'react';
import {
  LineChart, Line, BarChart, Bar, AreaChart, Area, RadarChart, Radar, PolarGrid,
  PolarAngleAxis, PolarRadiusAxis, XAxis, YAxis, Tooltip, CartesianGrid, Legend,
  ResponsiveContainer
} from 'recharts';// graph importss
import PopupCard from '../../Component/PopupCard.jsx';
import PopupcardAdd from '../../Component/PopupcardAdd.jsx';
import PopupTransfer from '../../Component/PopupTransfer.jsx';
import AdminSidebar from '../../Component/AdminSidebar.jsx';

const fetchRandomData = async () => {
  const response = await fetch('https://jsonplaceholder.typicode.com/posts'); // Example API
  const data = await response.json();
  return data.slice(0, 10).map((item, index) => ({
    name: `Item ${index + 1}`,
    value1: Math.floor(Math.random() * 100),
    value2: Math.floor(Math.random() * 100),
  }));
};

// *********************

const AnalyticsMainContent = () => {
  


  // const [activeItem, setActiveItem] = useState('Exchange stock');
  // const [showForm, setShowForm] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [buttonType, setButtonType] = useState('rightArrow');
  const [popupType, setPopupType] = useState(null);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      const randomData = await fetchRandomData();
      setData(randomData);
    };
    getData();
  }, []);
  const handleSetActiveItem = (item) => {
    // setActiveItem(item);
    // setShowForm(true);
    // setButtonType('rightArrow');

  if (item === 'Print Report') {
    setShowPopup(true);
    setPopupType('printReport');
  } 
  else if (item === 'Add Cylinder') {
    setShowPopup(true);
    setPopupType('addCylinder');
  }
    else if (item === 'Transfer Cylinder') {
    setShowPopup(true);
    setPopupType('transferCylinder'); 
  }
    else {
    setShowPopup(false);
    setPopupType(null);
  }
  };

const handleClosePopup = () => {
  setShowPopup(false);
  setPopupType(null);
};
  return (
    <div className="flex h-screen">
      <AdminSidebar setActiveItem={handleSetActiveItem}
      //  setShowForm={setShowForm}
       setShowPopup={setShowPopup}
        status="available"
        
       
       
       
      />

      <div className="flex flex-col flex-grow">
        <div className="flex-grow p-4">
          <h1 className="text-2xl font-bold mb-4">Main Content</h1>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-white p-4 shadow-md">
              <h2 className="text-lg font-semibold mb-4">Line Chart</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value1" stroke="#8884d8" />
                  <Line type="monotone" dataKey="value2" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="bg-white p-4 shadow-md">
              <h2 className="text-lg font-semibold mb-4">Bar Chart</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value1" fill="#8884d8" />
                  <Bar dataKey="value2" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="bg-white p-4 shadow-md">
              <h2 className="text-lg font-semibold mb-4">Area Chart</h2>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area type="monotone" dataKey="value1" stroke="#8884d8" fill="#8884d8" />
                  <Area type="monotone" dataKey="value2" stroke="#82ca9d" fill="#82ca9d" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="bg-white p-4 shadow-md">
              <h2 className="text-lg font-semibold mb-4">Radar Chart</h2>
              <ResponsiveContainer width="100%" height={300}>
                <RadarChart data={data}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="name" />
                  <PolarRadiusAxis angle={30} domain={[0, 100]} />
                  <Tooltip />
                  <Legend />
                  <Radar name="Value 1" dataKey="value1" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                  <Radar name="Value 2" dataKey="value2" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
     
      {showPopup && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
          {popupType === 'printReport' && <PopupCard onClose={handleClosePopup} />}
          {popupType === 'addCylinder' && <PopupcardAdd onClose={handleClosePopup} />}
          {popupType === 'transferCylinder' && <PopupTransfer onClose={handleClosePopup} />}
        </>
      )}
    </div>
  );
};

export default AnalyticsMainContent;