import React, { useState } from 'react';
import Sidebar from '../../Component/Sidebar';
import TopBar from '../../Component/TopBar';
import ExchangeMainContent from '../../Component/ExchangeMainContent';
import ExchangeContainer from '../../Component/ExchangeContainer';
import ExchangeCylinder from '../../Component/ExchangeCylinder'; // Import the ExchangeCylinder component

const ExchangeStock = () => {
  const [activeItem, setActiveItem] = useState('Exchange stock');
  const [searchQuery, setSearchQuery] = useState('');
  const [buttonType, setButtonType] = useState('rightArrow');
  const [showExchangeContainer, setShowExchangeContainer] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State for selected item
  const [showExchangeCylinder, setShowExchangeCylinder] = useState(false); // State for ExchangeCylinder modal

  // Handle sidebar item clicks
  const handleSetActiveItem = (item) => {
    setActiveItem(item);
    if (item === 'Exchange stock') {
      setShowExchangeContainer(false); // Hide ExchangeContainer
      setButtonType('rightArrow'); // Set default buttonType
    }
  };

  // Handle showing ExchangeContainer
  const handleShowExchangeContainer = (value) => {
    setShowExchangeContainer(value);
    if (value) {
      setButtonType('rightArrow'); // Adjust buttonType when showing ExchangeContainer
    }
  };

  // Handle item click from ExchangeMainContent
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowExchangeContainer(true); // Show ExchangeContainer
  };

  // Handle item selection from ExchangeCylinder
  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setShowExchangeContainer(true); // Show ExchangeContainer
    setShowExchangeCylinder(false); // Hide ExchangeCylinder modal
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        setActiveItem={handleSetActiveItem}
        setShowForm={() => setButtonType('addExchange')} // Update the buttonType when form is shown
        setButtonType={setButtonType}
        pageType="exchange"
        activeItem={activeItem}
      />
      <div className="flex flex-col flex-grow overflow-hidden pb-16">
        {showExchangeContainer ? (
          <ExchangeContainer
            selectedItem={selectedItem}
            label={selectedItem ? selectedItem.customerName : 'Customer Cylinder'} // Adjust label if necessary
          />
        ) : (
          <>
            {activeItem === 'Exchange stock' && <TopBar setSearchQuery={setSearchQuery} />}
            <ExchangeMainContent
              buttonType={buttonType}
              onItemClick={handleItemClick} // Ensure the function is passed correctly
              searchQuery={searchQuery}
              setShowExchangeContainer={handleShowExchangeContainer}
            />
          </>
        )}
      </div>
      {/* Show ExchangeCylinder Modal */}
      {showExchangeCylinder && (
        <ExchangeCylinder 
          showForm={showExchangeCylinder}
          setShowForm={setShowExchangeCylinder}
          onItemSelect={handleItemSelect} // Pass item select handler
        />
      )}
    </div>
  );
};

export default ExchangeStock;