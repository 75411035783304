export const colors = ['#ae0000', '#00ae26', '#0050ae', '#33ff57'];

const colorMapping = {
  '#ae0000': 'Bright Red',
  '#00ae26': 'Green',
  '#0050ae': 'Dark Blue',
  '#ff5733': 'Red Orange',
  '#33ff57': 'Lime Green',
  '#3357ff': 'Royal Blue',
  '#f1c40f': 'Sunflower Yellow',
  '#9b59b6': 'Amethyst Purple',
  "#ffff00": 'Yellow',
  "#000000": 'Black',
  "#ff0000": 'Red'
};

export default colorMapping;
