import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

const colorOptions = [
  { color: '#FF0000', label: 'Red' },
  { color: '#008000', label: 'Green' },
  { color: '#0000FF', label: 'Blue' },
  { color: '#FFFF00', label: 'Yellow' },
  { color: '#800080', label: 'Purple' },
];

const gasTypes = [
  'Oxygen',
  'Nitrogen',
  'Hydrogen',
  'Carbon Dioxide',
  'Helium'
];

const AddCylinder = ({ showForm, setShowForm, onSubmit }) => {
  const [formData, setFormData] = useState({
    serialNumber: '',
    capacity: '',
    color: colorOptions[0].color, // Default color
    gasType: gasTypes[0], // Default gas type
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleColorChange = (color) => {
    setFormData({ ...formData, color });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (typeof onSubmit === 'function') {
      onSubmit(formData); // Call parent onSubmit handler with form data
    } else {
      console.error('onSubmit is not a function');
    }
    setShowForm(false); // Close the popup form after submission
  };

  const renderColorOptions = () => {
    return colorOptions.map((option, index) => (
      <div
        key={index}
        onClick={() => handleColorChange(option.color)}
        className={`w-10 h-10 rounded cursor-pointer mr-2 border-2 ${
          formData.color === option.color ? 'border-black' : 'border-transparent'
        } flex items-center justify-center`}
        style={{ backgroundColor: option.color }}
        title={option.label} // Tooltip to show color name on hover
      >
        {formData.color === option.color && (
          <span className="text-white text-xs">{option.label}</span>
        )}
      </div>
    ));
  };

  if (!showForm) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-60 transition-opacity duration-300">
      <form className="bg-white p-8 rounded shadow-lg transform transition-all duration-300 ease-in-out max-w-3xl w-full relative" onSubmit={handleFormSubmit}>
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 transition-transform transform hover:scale-110 focus:outline-none focus:ring focus:ring-gray-300 rounded-full p-1"
          onClick={() => setShowForm(false)}
        >
          <FiX className="text-2xl" />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">Add Customer Cylinder</h2>
        <div className="mb-6" style={{ width: '95%', margin: '0 auto' }}>
          <input
            type="text"
            name="serialNumber"
            placeholder="Serial Number"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={handleInputChange}
            value={formData.serialNumber}
            required
          />
        </div>
        <div className="mb-6" style={{ width: '95%', margin: '0 auto' }}>
          <input
            type="text"
            name="capacity"
            placeholder="Capacity"
            className="mt-5 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={handleInputChange}
            value={formData.capacity}
            required
          />
        </div>
        <div className="flex items-center mb-6" style={{ width: '95%', margin: '0 auto', marginTop: '20px' }}>
          <div className="flex">
            {renderColorOptions()}
          </div>
        </div>
        <div className="mb-6" style={{ width: '95%', margin: '0 auto' }}>
          <select
            name="gasType"
            className="mt-5 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={handleInputChange}
            value={formData.gasType}
            required
          >
            {gasTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="mt-5 px-4 py-2 bg-[#4EA89E] text-white rounded hover:bg-blue-600"
          >
            Add Exchange
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCylinder;
